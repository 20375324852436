// extracted by mini-css-extract-plugin
export var overviewTextWrap = "conference-module--overviewTextWrap--PuQLn";
export var overviewText = "conference-module--overviewText---dwKT";
export var speakersWrap = "conference-module--speakersWrap--Ng4s-";
export var speakersHeader = "conference-module--speakersHeader--LiZIu";
export var speakersTitle = "conference-module--speakersTitle--t+PDs";
export var speakersContainer = "conference-module--speakersContainer---8j5F";
export var speakersInnerContainer = "conference-module--speakersInnerContainer--2UOat";
export var primaryItem = "conference-module--primaryItem--aJbcC";
export var bioImage = "conference-module--bioImage--g6fOf";
export var staffName = "conference-module--staffName--YdS0B";
export var staffPosition = "conference-module--staffPosition--Xci7g";
export var socialWrap = "conference-module--socialWrap--kcc5E";
export var sectionHeading = "conference-module--sectionHeading--Ypzkz";
export var primaryStaff = "conference-module--primaryStaff--Xqb3r";
export var sponsorsSection = "conference-module--sponsorsSection--vjEh8";
export var sectionInset = "conference-module--sectionInset--sB-7h";
export var sectionItem = "conference-module--sectionItem--crXNh";
export var sponsorItem = "conference-module--sponsorItem--DgP6T";
export var sponsorLogo = "conference-module--sponsorLogo--d1got";
export var slideUp = "conference-module--slideUp--4YFHi";
export var slideUpLarge = "conference-module--slideUpLarge--AEq+Y";