import React, { Component } from 'react';
import $ from 'jquery';
import Button from '../ui/button';
import * as style from './faq.module.scss';

class Faq extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
			activeFaq: 0,
		};
	}
	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', () => {this.updateWindowDimensions()});
	}
	componentWillUnmount() {
		window.removeEventListener('resize', () => {this.updateWindowDimensions()});
	}
	updateWindowDimensions() {
		this.setState({ width: window.innerWidth });
	}
	setActiveFaq(faqNum) {
		var newFaq = parseInt(faqNum);
		var oldFaq = parseInt(this.state.activeFaq);

		if (newFaq != oldFaq) {
			this.setState({ activeFaq: parseInt(newFaq) });
		} else if (newFaq == oldFaq) {
			this.setState({ activeFaq: parseInt(0) });
		}
	}

	render() {
		const width = (this.state) ? this.state.width: 0;

		let activeFaq = this.state.activeFaq;

		/*
		$(document).ready(function() {
			console.log('SCHEDULE RENDER() READY...');
		});
		*/

		var componentRef = this;

		return (
			<div id="faq-wrap">
				<h1 className={`${style.faqTitle} faq-title`}>Frequently Asked Questions</h1>		
				<div id="faq-wrap-inset">	
					<div
						id="faq-items"
						className={`${style.faqItems} faq-items`}
					>
						{this.props.faq.length > 0 && this.props.faq.map(function(item, i) {
							var currentIndex = parseInt(1 + i);

							var faqClass = '';
							if (activeFaq == currentIndex) {
								faqClass = 'active';
							}

							return (
								<div
									className={`${style.faqItem} faq-item `+faqClass}
								>
									<div
										className={`${style.faqTarget} faq-target `+faqClass+` faq-`+currentIndex}
										onClick={() => componentRef.setActiveFaq(currentIndex)}
									>
										{item.question}
										<span className='plus'>
											<i class="fa-solid fa-plus"></i>
										</span>
									</div>

									{activeFaq == currentIndex &&
										<div className={`${style.faqMeta} faq-meta`}>
											<div
												className={`${style.faqAnswer} faq-answer`}
												dangerouslySetInnerHTML={{ __html: item.answer }}
											/>
										</div>
									}
								</div>
							);
						})}
					</div>
					
				</div>
			</div>
		);
	}
};

export default Faq;
